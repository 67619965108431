/*.catalogue-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: "Lato", sans-serif;
}

.catalogue-content {
  width: 100%;
  max-width: 1200px;
}

.catalogue-heading {
  text-align: center;
  margin-bottom: -20px;
  font-size: 3rem;
  color: #26017b;
  margin-right: 20rem;
}

.catalogue-subheading {
  text-align: center;
  font-size: 2rem;
  color: #00a5e0;
  margin-right: 20rem;
}

.catalogue-inner {
  display: flex;
  justify-content: space-between;
}

.left-section {
  flex: 1;
  padding-right: 10rem;
}

.left-section img {
  width: 100%;
  max-width: 500px;
  margin-right: 2rem;
  margin-top: -2rem;
}

.right-section {
  flex: 1;
  margin-top: 2rem;
}

.catalogue-form .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #26017b;
  font-size: 1.3rem;
  font-weight: bold;
}

.catalogue-form .form-row input,
.catalogue-form .form-row select,
.catalogue-form .form-row .react-phone-number-input,
.custom-datepicker {
  width: 100%;
  height: 2.5rem;
  border-radius: 100px;
  padding: 0 10px;
  font-size: 13px;
  border: 2px solid #00a5e0;
}

.react-phone-number-input input {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 13px;
  padding: 0 10px;
}

.react-phone-number-input input:focus {
  outline: none;
  border: none;
}

.react-phone-number-input:focus-within {
  outline: none;
  box-shadow: none;
}

.react-phone-number-input input {
  background-color: transparent;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.react-phone-number-input-input {
  border: 2px solid #00a5e0 !important;
  border-radius: 100px;
  width: 100%;
  height: 2.5rem;
  padding-left: 10px;
}

.PhoneInputInput {
  border: white;
  font-size: 13px;
  height: 1.5rem;
  outline: none;
}

.react-phone-number-input input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.catalogue-form .form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-group {
  width: 100%;
}

.name-inputs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.download-link {
  width: 100%;
  height: 2.5rem;
  padding: 0 10px;
  background-color: #26017b;
  color: white;
  border: #26017b solid 2px;
  border-radius: 100px;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 2rem;
  transition: background-color 0.3s;
}

.download-link:hover {
  cursor: pointer;
  color: #26017b;
  background-color: white;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.custom-datepicker {
  border: none;
  outline: none;
  width: 90%;
  height: 100%;
  padding: 0 10px;
  font-size: 13px;
  border-radius: 100px;
}

.react-datepicker__input-container input {
  border: none;
  outline: none;
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__time-list-item {
  padding: 10px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #00a5e0;
}

.catalogue-form input,
.catalogue-form select,
.react-phone-number-input input,
.custom-datepicker {
  width: 100%;
  height: 2.5rem;
  border-radius: 100px;
  padding: 0 10px;
  font-size: 13px;
  border: 2px solid #00a5e0;
}

.catalogue-form input:focus,
.catalogue-form select:focus,
.react-phone-number-input input:focus,
.custom-datepicker:focus {
  border-color: #00a5e0;
}

.catalogue-form label {
  color: #26017b;
  font-weight: bold;
  font-size: 1.3rem;
}

.download-link {
  background-color: #26017b;
  color: white;
  border-radius: 100px;
  font-size: 1.5rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 10px;
  margin-top: 2rem;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .catalogue-inner {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    padding-right: 0;
    margin-top: 2rem;
  }

  .left-section img {
    max-width: 90%;
  }

  .catalogue-heading {
    margin-right: 0;
    font-size: 2.5rem;
  }

  .catalogue-subheading {
    margin-right: 0;
    font-size: 2rem;
  }

  .catalogue-form .form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-group label {
    margin-bottom: 10px;
  }

  .catalogue-form .form-row input,
  .catalogue-form .form-row select,
  .catalogue-form .form-row .react-phone-number-input,
  .custom-datepicker {
    width: 100%;
  }

  .name-inputs {
    flex-direction: column;
    gap: 0;
  }

  .input-group {
    margin: 0 0;
  }

  .download-link {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: background-color 0.3s;
  }

  .download-link:hover {
    cursor: pointer;
    color: #26017b;
    background-color: white;
  }
}
*/

.phone-input-custom input {
  border: none !important;
  outline: none !important;
  flex-grow: 1;
}

.phone-input-custom {
  width: 100%; /* Ensure PhoneInput takes full width */
  
  display: flex;
  flex-direction: row;
}

.form-control {
  width: 100%; /* Ensure all form controls take full width */
}

.custom-date-picker {
  width: 110%; /* Ensures it takes full width */
  border: none;
  border-radius: 100px; /* Rounded corners */
  padding: 0.375rem 0.75rem; /* Add some padding */
  box-shadow: none; /* Remove any box shadow if present */
  outline: none;
}

.custom-date-picker:focus {
  outline: none; /* Remove the outline on focus */
  box-shadow: none; /* Ensure there's no box shadow on focus */
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none;
}
