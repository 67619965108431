.whatsapp-container {
  position: fixed;
  bottom: 25px;
  left: 0px;
  background-color: rgb(37, 211, 102);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 1s ease, height 1s ease, padding 0.5s ease; /* Unified transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 50px;
  height: 50px;
  padding: 0;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.whatsapp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 10%;
}

.whatsapp-img {
  width: 70%;
  height: auto;
}

.whatsapp-container.expanded {
  width: auto; /* Set to auto to allow dynamic sizing */
  height: auto;
  padding: 0 15px; /* Adds padding when expanded */
  transition: width 1s ease, height 1s ease, padding 0.2s ease; /* Ensures slow collapse */
}

.whatsapp-text {
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
  white-space: nowrap;
  text-align: right;
}

@media (max-width: 1025px) {
  .whatsapp-text {
    font-family: "Lato";
  }
}

@media (max-width: 768px) {
  .whatsapp-text {
    font-family: "Lato";
  }
}
