/*.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-left: 0;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin-bottom: 0.7rem;
  position: relative;
  font-family: "Lato", sans-serif;
}

.hero-text {
  flex: 1;
  padding: 2rem;
  margin-top: 5rem;
}

.subheading {
  color: #26017b; 
  font-size: 3rem;
  text-align: left;
  font-family: "Lato", sans-serif;
}
.hero-quote {
  font-family: "Lato", sans-serif;
  margin-top: -1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.details {
  color: #26017b; 
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 2rem;
}

.custom-list {
  list-style: none; 
  margin-left: 0; 
  padding-left: 0; 
}

.custom-list li {
  position: relative; 
  padding-left: 30px; 
  margin-bottom: 12px; 
}

.custom-list li::before {
  content: '';
  position: absolute;
  left: 0; 
  top: 50%; 
  transform: translateY(-50%); 
  width: 20px; 
  height: 20px; 
  background: url('../assets/icons/arrow.png') no-repeat center center;
  background-size: contain; 
}

.pricing {
  font-size: 1.75rem;
  margin: 1.8rem 0;
}

.discounted-price {
  color: #00a5e0; 
  margin-right: 0.5rem;
  font-size: 2.2rem;
  font-weight: 600;
}

.original-price {
  color: #333;
  text-decoration: line-through;
  font-size: medium;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.start-now {
  background: #00a5e0; 
  color: #fff;
  border: 2px solid #00a5e0;
  padding: 1rem 1.5rem;
  border-radius: 90px;
  font-size: 1.25rem;
  transition: background-color 0.3s;
  font-weight: 600;
}

.timer {
  background: #26017b;
  color: #fff;
  border: 2px solid #26017b;
  padding: 1rem 1rem;
  border-radius: 90px;
  font-size: 1.25rem;
  transition: background-color 0.3s;
  font-weight: 600;
}

.start-now:hover {
  background: #fff;
  color: #00a5e0;
  border: 2px solid #00a5e0;
  cursor: pointer; 
}

.limited-time-deal {
  color: #26017b;
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.limited-time-deal span {
  margin-right: 0.25rem;
}

.hero-image {
  flex: 1;
  padding: 2rem;
  width: 2rem;
  height: auto;
  position: relative; 
  top: 3rem; 
  bottom: 5rem; 
}

.hero-image img {
  max-width: 93%;
  height: auto;
  transform: translate(-10px, -8px) scale(1.1); 
  margin-left: 5rem; 
  margin-top: 22px; 
}

.social-icons {
  position: absolute;
  top: 50%;
  left: -16.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform: translateY(30%);
}

.social-icons .icon-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.social-icons .icon-box i {
  color: #fff;
  font-size: 2rem;
  position: absolute;
  left: 10px;
}

.social-icons .icon-box span {
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  font-size: 1rem;
  font-weight: bold;
}

.social-icons .icon-box:hover {
  width: 180px;
}

.icon-box:hover span {
  opacity: 1;
}

.facebook {
  background-color: #316ff6;
}

.twitter {
  background-color: #00acee;
}

.instagram {
  background-color: #e1306c;
}

.linkedin {
  background-color: #0077b5;
}

.github {
  background-color: #171515;
}

.youtube {
  background-color: #ff0000;
}


.support-features {
  width: 100%;
  text-align: center;
  margin-top: 8rem;
}

.support-heading {
  color: #000;
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
  font-family: "Inter", sans-serif;
}

.support-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
}

.support-icon {
  flex: 1;
  padding: 2rem;
  text-align: center;
  transition: transform 0.5s ease-in-out;
}

.support-icon img {
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
}

.support-icon h3 {
  color: #000;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: "Times New Roman", Times, serif;
  font-family: "Inter", sans-serif;
}

.support-icon p {
  color: #555;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.support-icon:nth-child(1) {
  animation: slide-in-top 2s forwards;
}

.support-icon:nth-child(2) {
  animation: slide-in-top 2s forwards;
}

.support-icon:nth-child(3) {
  animation: slide-in-top 2s forwards;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@media screen and (max-width: 1024px) {
  .hero-content {
    flex-direction: column;
    text-align: left;
  }

  .hero-text {
    margin-top: 2rem;
    padding: 1.5rem;
  }

  .subheading {
    font-size: 2.5rem;
  }

  .hero-quote, .details {
    font-size: 1.1rem;
  }

  .pricing {
    font-size: 1.5rem;
  }

  .discounted-price {
    font-size: 2rem;
  }

  .original-price {
    font-size: small;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .start-now, .timer {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }

  .limited-time-deal {
    font-size: 0.9rem;
    margin-top: 0.8rem;
  }

  .hero-image {
    margin-top: 1.5rem;
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .hero-image img {
    margin: 0;
    max-width: 80%;
  }

  .social-icons {
    left: 0;
    top: 90%;
    transform: translateY(0);
    flex-direction: row;
    gap: 0.5rem;
    position: relative;
  }

  .icon-box {
    width: 40px;
    height: 40px;
  }

  .icon-box i {
    font-size: 1.5rem;
  }

  .support-heading {
    font-size: 2.5rem;
  }

  .support-icons {
    flex-direction: column;
    margin-top: 2rem;
  }

  .support-icon {
    padding: 1.5rem;
  }

  .support-icon h3 {
    font-size: 2rem;
  }

  .support-icon p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-content {
    text-align: left;
  }

  .hero-text {
    margin-top: 1rem;
    padding: 1rem;
  }

  .subheading {
    font-size: 2rem;
  }

  .hero-quote, .details {
    font-size: 1rem;
  }

  .pricing {
    font-size: 1.25rem;
  }

  .discounted-price {
    font-size: 1.75rem;
  }

  .original-price {
    font-size: small;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .start-now, .timer {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .limited-time-deal {
    font-size: 0.85rem;
  }

  .hero-image img {
    max-width: 100%;
    margin: 0;
  }

  .social-icons {
    position: static;
    margin-top: 2rem;
    transform: none;
  }

  .icon-box {
    width: 35px;
    height: 35px;
  }

  .icon-box i {
    font-size: 1.2rem;
  }

  .support-heading {
    font-size: 2rem;
  }

  .support-icons {
    flex-direction: column;
  }

  .support-icon {
    padding: 1rem;
  }

  .support-icon h3 {
    font-size: 1.5rem;
  }

  .support-icon p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .subheading {
    font-size: 1.5rem;
  }

  .hero-quote, .details {
    font-size: 0.9rem;
  }

  .pricing {
    font-size: 1rem;
  }

  .discounted-price {
    font-size: 1.5rem;
  }

  .start-now, .timer {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }

  .limited-time-deal {
    font-size: 0.75rem;
  }

  .hero-image img {
    max-width: 100%;
  }

  .support-heading {
    font-size: 1.5rem;
  }

  .support-icon h3 {
    font-size: 1.2rem;
  }

  .support-icon p {
    font-size: 0.9rem;
  }
}
*/

.start-now {
  background: #00a5e0; 
  color: #fff;
  border: 2px solid #00a5e0;
  border-radius: 90px;
  font-size: 1.25rem;
  transition: background-color 0.3s;
  font-weight: 600;
}

.start-now:hover {
  background: #fff;
  color: #00a5e0;
  border: 2px solid #00a5e0;
  cursor: pointer; 
}

.timer {
  background: #26017b;
  color: #fff;
  border: 2px solid #26017b;
  padding: 1rem 1rem;
  border-radius: 90px;
  font-size: 1.25rem;
  transition: background-color 0.3s;
  font-weight: 600;
}

.custom-list li {
  position: relative;
  padding-left: 30px; /* Adjust padding to accommodate the icon size */
  background-image: url("../assets/icons/arrow.png");
  background-repeat: no-repeat;
  background-position: 0 5px; /* Adjust position of the icon */
  background-size: 20px 20px; /* Adjust the size of the icon */
}
