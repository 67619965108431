/*.chooseplan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: "Lato", sans-serif;
}

.chooseplan-box {
  margin: 0;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  min-height: fit-content;
}

.chooseplan-top {
  display: flex;
  align-items: left;
}

.chooseplan-top img {
  width: 64px;
  height: 64px;
}

.chooseplan-top h2 {
  font-family: "Gluten", cursive;
  font-weight: bold;
  font-size: 1.5rem;
}

.chooseplan-box h3 {
  color: #26017b;
  font-size: 1.8rem;
  font-weight: bold;
}

.chooseplan-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.chooseplan-left,
.chooseplan-right {
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
  width: auto;
  height: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); 
}

.chooseplan-left:last-child {
  margin-right: 0;
}

.plan-name select {
  background-color: transparent;
  color: #26017b;
  font-size: 1.2rem;
  border: none;
  outline: none;
  padding: 3px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  position: relative;
  font-weight: bold;
}

.plan-name select::-ms-expand {
  display: none;
}

.plan-name {
  position: relative;
}

.plan-name select option {
  background-color: white;
  color: #00a5e0;
  font-family: "Lato", sans-serif;
}

.plan-name select:hover {
  border-color: #26017b;
}

.chooseplan-right {
  margin-right: 0;
}

.fullplan-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10rem;
}

.planleft-container,
.planright-container {
  flex: 1;
  flex-wrap: nowrap;
}

.discount-container h4,
.discount-container h5 {
  margin: 0;
  text-wrap: nowrap;
  font-family: "Lato", sans-serif;
}

.discount-container h4 {
  background-color: rgba(0, 184, 95, 0.88);
  padding: 10px;
  color: white;
  text-align: right;
  border-radius: 200px;
}

.discount-container h5 {
  color: #26017b;
  margin-top: 5px;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
}

.plan-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: "Lato", sans-serif;
  flex-wrap: nowrap;
}

.plan-details label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
}

.plan-details select {
  border: 1px solid black;
  background-color: transparent;
  color: black;
  padding: 0.5rem;
  font-size: 1.1rem;
  outline: none;
  cursor: pointer;
}

.plan-details select option {
  background-color: white;
  color: black;
}

.plan-details p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  margin-top: 0.5rem;
  text-wrap: nowrap;
}

.choosebottom-strip {
  background-color: rgba(38, 1, 123, 0.2);
  text-wrap: nowrap;
  text-align: center;
  padding: 5px;
  font-size: 20px;
  color: black;
  margin-top: 7rem;
}

.subtotal {
  font-size: 1.7rem;
  color: #26017b;
  font-weight: bold;
}

.price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancelled-discount {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 50px;
  font-size: 20px;
}

.cancelled-discount::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: #26017b;
  transform: translateY(-50%);
}

.actual-price {
  font-weight: bold;
  color: #26017b;
  font-size: 20px;
}

.discount-tag {
  color: #26017b;
  font-weight: bold;
}

.discount-price {
  color: rgba(0, 184, 95, 0.88);
  font-weight: bold;
}

.checkout-content {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0%;
}

.checkout-btn {
  background-color: #26017b;
  color: white;
  border: #26017b solid 2px;
  padding: 5px;
  font-size: 1.5rem;
  width: 100%;
  transition: background-color 0.3s;
}

.checkout-btn:hover {
  cursor: pointer;
  background-color: transparent;
  color: #26017b;
}

.note {
  font-size: 0.8rem;
  color: #26017b;
  font-weight: bold;
  text-align: left;
  margin-bottom: -8rem;
  margin-top: 5px;
}

@media (max-width: 1200px) {
  .chooseplan-container {
    padding: 20px;
  }

  .chooseplan-content {
    flex-direction: column;
  }

  .chooseplan-left,
  .chooseplan-right {
    margin: 10px 0;
    min-width: 100%;
  }

  .fullplan-detail {
    flex-direction: column;
    gap: 5rem;
  }

  .choosebottom-strip {
    margin-top: 4rem;
  }

  .checkout-btn {
    font-size: 1.2rem;
    margin-top: -11rem;
  }
}

@media (max-width: 768px) {
  .chooseplan-top img {
    width: 48px;
    height: 48px;
    margin-left: 20px;
  }

  .chooseplan-top h2 {
    font-size: 1.2rem;
    font-family: "Inter", sans-serif;
  }

  .chooseplan-box h3 {
    font-size: 1.5rem;
    margin-left: 20px;
  }

  .chooseplan-left,
  .chooseplan-right {
    padding: 10px;
  }

  .subtotal {
    font-size: 1.4rem;
  }

  .price-box {
    flex-direction: column;
  }

  .checkout-btn {
    font-size: 1rem;
    padding: 10px;
    margin-top: -11rem;
  }
}

@media (max-width: 480px) {
  .chooseplan-container {
    flex-direction: column;
    padding: 10px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .chooseplan-box {
    padding: 15px;
  }

  .chooseplan-left,
  .chooseplan-right {
    margin-right: 0;
  }

  .discount-container h4 {
    font-size: 1rem;
    padding: 5px;
  }

  .checkout-btn {
    font-size: 0.8rem;
    margin-top: -11rem;
  }

  .note {
    font-size: 0.7rem;
  }
}

*/

.choose-plan{
  font-family: 'Lato', sans-serif;
}