/*
.top-strip {
  background: #00a5e0;
  color: #26017b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  height: 1.6rem;
  top: 0;
  left: 0;
  z-index: 1001;
  font-family: "Inter", sans-serif;
}

.contact-left,
.contact-right {
  display: flex;
  align-items: center;
}

.contact-left {
  padding-left: 20px;
}

.contact-right {
  padding-right: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item i {
  margin-right: 8px;
}

.request-call {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.request-call-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.request-call-link:hover {
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 1.3rem;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 1000;
  padding: 0.5rem 0;
  transition: width 0.3s ease;
  font-family: "Gluten", cursive;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.nav-link {
  color: #26017b;
  text-decoration: none;
  font-weight: 650;
  transition: color 0.3s;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.nav-link.active {
  border-bottom: 3px solid #00a5e0;
}

.nav-link:hover {
  color: #00a5e0;
}

.nav {
  font-family: "Titillium Web";
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}

.nav * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 1em;
  padding: 0;
}

.nav a {
  padding: 0.5em 0.8em;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  text-decoration: none;
  font-size: 20px;
}

.nav a::before,
.nav a::after {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.nav a::before {
  content: "";
  right: 0;
  top: 0;
  border-top: 3px solid #3e8914;
  border-right: 3px solid #2e640f;
  transform: translate(-100%, 50%);
}

.nav a:after {
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 3px solid #2e640f;
  border-left: 3px solid #3e8914;
  transform: translate(100%, -50%);
}

.nav a:hover:before,
.nav a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.nav a:hover {
  color: #3da35d;
}

.navbar-logo {
  font-size: 2.5rem;
  font-weight: bolder;
  padding-left: 1rem;
}

.logo-fuzz {
  color: #26017b;
}

.logo-ads {
  color: #00a5e0;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.navbar-menu li a {
  color: #26017b;
  text-decoration: none;
  font-weight: 650;
  transition: color 0.3s;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
}

.navbar-menu li a:hover {
  color: #00a5e0;
}

.login-button {
  padding-right: 1rem;
  text-decoration: none;
  font-size: 1rem;
  background-color: #00a5e0;
}

.login-button:hover {
  background-color: #26017b;
}

.hamburger {
  display: none;
}

@media (max-width: 1025px){
  .navbar{
    font-family: "Lato";
  }
}


#nprogress .bar {
  background: #29d; 
  height: 4px; 
  position: fixed;
  top: 60px; 
  left: 0;
  width: 100%;
  z-index: 1031; 
}


#nprogress .spinner {
  display: none;
}


#nprogress .peg {
  box-shadow: 0 0 10px #29d, 0 0 5px #29d; 
}


@media (max-width: 768px) {
  .navbar {
    font-family: "Gluten";
    background-color: white;
  }
  .top-strip {
    display: none;
    width: 0;
    height: 0px;
  }

  .contact-left,
  .contact-right {
    display: none;
  }

  .request-call {
    flex-grow: 1;
    text-align: center;
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .navbar-logo {
    flex-grow: 0;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .hamburger {
    display: block;
    position: absolute;
    left: 1rem;
  }

  .navbar-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0; 
    left: -100%;
    width: 70%;
    height: 100vh;
    background-color: #00a5e0;
    transition: 0.3s ease-in-out;
    padding-top: 0;
    margin: 0;
  }

  .navbar-menu.active {
    left: 0;
  }

  .nav-link {
    margin: 1rem 0;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    margin-top: 1rem;
  }

  .nav-link:hover{
    color: #26017b;
  }

  .navbar-bg {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .hamburger {
    z-index: 1000;
    cursor: pointer;
  }

  .hamburger svg {
    transition: transform 0.3s ease;
  }

  .hamburger svg:hover {
    transform: rotate(90deg);
  }
  .navbar-buttons .login-button {
    font-size: 10px;
    padding: 0.5rem;
    position: absolute;
    right: -10px;
    text-wrap: nowrap;
    top: -10px;
    text-align: center;
    font-weight: bold;
  }

  .navbar-buttons {
    position: absolute;
    right: 1rem;
    width: 80px;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(179, 175, 175, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: rgba(179, 175, 175, 0.8);
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 1rem;
  width: fit-content;
  min-width: auto;
  animation: fadeIn 0.3s ease-in-out;
  margin: 0 auto;
  z-index: 1001;
}

.popup-content .popup-heading{
  margin-top: 10px;
  font-size: 2rem;
}


.close-button {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #26017b;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #26017b;
  font-size: 3rem;
  font-weight: bold;
}

.call-form .form-group {
  margin-bottom: 1.5rem;
}

.call-form label {
  display: block;
  margin-top: 1rem;
  margin-bottom: 5px;
  color: #26017b;
  font-size: 1.1rem;
  font-weight: bold;
}

.call-form input,
.call-form .PhoneInputInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #26017b;
  border-radius: 100px;
  font-size: 1rem;
}

.call-form .PhoneInputInput {
  width: 90%;
  padding: 8px;
  font-size: 1rem;
  height: 2.2rem;
}

.submit-link {
  width: 80%;
  padding: 8px;
  background-color: #26017b;
  color: white;
  border: solid #26017b;
  border-radius: 100px;
  cursor: pointer;
  font-size: 1.3rem;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  transition: background-color 0.3s;

}

.submit-link:hover {
  background-color: #fff;
  color: #26017b;
  border: solid #26017b;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.user-icon-container {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
}

.user-initials {
  background-color: #26017b;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  display: inline-block;
  text-align: center;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.user-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-item {
  color: #00a5e0;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}

.loader {
  width: 100px;
  height: 100px;
}

.loader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.loader-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #26017b;
  z-index: 9999;
  animation: loader-animation 1s ease-out infinite;
}

@keyframes loader-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

*/

/* Add this to your CSS file */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; /* Ensure it stays above other elements */
  transition: background-color 0.3s ease;
  font-family: 'Inter', sans-serif; 
}

.navbar-brand {
  font-family: "Gluten", sans-serif;
}

.nav-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; /* Ensure it stays above other elements */
  transition: background-color 0.3s ease;
  background-color: white;
}

.loader-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: white;
  animation: loading 1s ease-in-out;
  z-index: 10000;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 576px) {
  /* For small screens and up */
  .navbar {
    font-family: "Inter", sans-serif; /* For desktop */
  }
}

.nav-link.active {
  border-bottom: 2px solid #00a5e0; /* Active link underline */
  padding-bottom: 5px; /* Space for the underline */
  
}

