/*html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  font-family: "Lato", sans-serif;
}

.contact-video {
  position: relative;
  width: 100%;
  height: 400px;
  background-image: url("../assets/contact-gif.gif");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-video h2 {
  position: absolute;
  color: #00a5e0;
  font-size: 4rem;
  text-align: center;
}

.contact-content {
  margin-top: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-content h2 {
  font-weight: normal;
  color: #26017b;
}

.contact-content h2 span {
  color: #00a5e0;
}

.contact-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  max-width: 1300px;
  padding: 0 10px;
}

.box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  border: #26017b solid 4px;
}

.box:hover {
  transform: scale(1.05);
}

.box-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.box p {
  font-size: 1.2rem;
  color: #26017b;
  font-weight: bold;
}

.main-contact {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 5rem;
}

.left-contact {
  flex: 1;
  margin-right: 15rem;
}

.left-contact p {
  font-size: 1.1rem;
}


.custom-phone-input-container .react-phone-number-input__icon {
  background-color: none !important; 
  outline: none !important;
  box-shadow: none !important;
}


.custom-phone-input-container .react-phone-number-input__icon:hover {
  background-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.custom-phone-input-container .react-phone-number-input__icon:focus {
  background-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.loader {
  width: 80px;
  height: 80px;
}


.blur {
  filter: blur(5px);
  pointer-events: none; 
}



.maincontact-form {
  display: flex;
  flex-direction: column;
}

.maincontact-form label {
  color: #26017b;
  font-weight: bold;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}

.maincontact-form input,
.maincontact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 50px;
  border: 3px solid #26017b;
  font-size: 1rem;
}

.maincontact-form textarea {
  height: 100px;
  resize: none;
}

.maincontact-form textarea::placeholder {
  font-family: "Lato", sans-serif;
}

.maincontact-form button {
  padding: 10px;
  background-color: #26017b;
  color: #fff;
  border: 2px solid #26017b;
  border-radius: 50px;
  cursor: pointer;
  font-size: 2rem;
  text-align: center;
  justify-content: center;
}

.maincontact-form button img {
  width: 30px;
  height: auto;
}

.maincontact-form button:hover {
  background-color: #00a5e0;
  border: 2px solid #00a5e0;
}

.right-contact {
  flex: 1;
}

.quick-links button {
  display: block;
  width: 400px;
  margin: 10px 0;
  padding: 10px;
  margin-left: 5rem;
  background-color: transparent;
  border: #26017b 2px solid;
  color: #26017b;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.quick-links button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.contactsocial-links {
  display: flex;
  gap: 0.8rem;
  margin-left: 8rem;
  margin-bottom: 3rem;
}

.contactsocial-links img {
  width: 12%;
  height: auto;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
}

.contactsocial-links img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.quotebox {
  flex: 1;
  margin: 0 10px;
  text-align: center;
  padding: 30px;
  border: #26017b solid 2px;
  max-width: 25rem;
  margin-left: 5rem;
}

.quotebox p {
  font-size: 1.3rem;
  color: #00a5e0;
  font-weight: bold;
}

.quote-img {
  margin-right: 15rem;
  width: 30px;
  height: auto;
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-img {
  width: 70px;
  height: auto;
  margin-top: 2rem;
  margin-left: 20px;
}

.profile-text {
  margin-top: 2rem;
  margin-left: 2.5rem;
  margin-right: 2rem;
  white-space: nowrap;
}

.profile-text h5 {
  color: #26017b;
  font-size: 1.2rem;
  font-weight: bold;
}

.profile-text p {
  color: #00a5e0;
  margin-top: 0.3rem;
}

@media (max-width: 1024px) {
  .main-contact {
    flex-direction: column;
    align-items: center;
  }

  .left-contact {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-contact {
    margin-left: 0;
  }

  .contact-boxes {
    flex-direction: column;
    padding: 0;
  }

  .box {
    margin: 10px 0;
    width: 100%;
  }

  .quick-links button {
    width: 100%;
    margin-left: 0;
  }

  .contactsocal-links {
    margin-left: 0;
  }

  .quotebox {
    margin-left: 0;
    max-width: 100%;
  }

  .maincontact-form {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .contact-video h2 {
    font-size: 2.5rem;
  }

  .contact-content {
    font-size: 1.2rem;
  }

  .contact-content h2 {
    font-size: 1.5rem;
  }

  .main-contact {
    flex-direction: column;
  }

  .contact-boxes {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }

  .box {
    margin: 10px 10px;
  }

  .contact-container {
    width: 100%;
    padding: 0;
    min-height: auto;
  }

  .quick-links button {
    width: 100%;
  }

  .quick-links {
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }

  .quick-links h2 {
    font-size: 1.8rem;
  }
  .maincontact-form {
    max-width: 90%;
  }

  .contactsocial-links{
    align-items: center;
    margin-left: 3rem;
  }

  .quotebox{
    max-width: 100%;
  }
}
*/

.maincontact-form button {
  padding: 1px;
  background-color: #26017b;
  color: #fff;
  border: 2px solid #26017b;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.6rem;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.maincontact-form button img {
  width: 30px;
  height: auto;
}

.maincontact-form button:hover {
  background-color: #00a5e0;
  border: 2px solid #00a5e0;
  color: #ffffff;
}

.contact-video{
  margin-top: 9rem;
}

.phone-input-custom input {
  border: none !important;
  outline: none !important;
  flex-grow: 1;
}

.phone-input-custom {
  width: 100%; /* Ensure PhoneInput takes full width */
  
  display: flex;
  flex-direction: row;
}