

body{
  font-family: "Lato", sans-serif;
}

.choose-us button {
  color: white;
  background-color: #00a5e0;
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: none;
  border-radius: 100px;
  padding: 10px;
  display: flex;
  margin: 0 auto;
  width: fit-content; 
  transition: background-color 0.3s, color 0.3s;
  border: 2px solid #26017b;
}

.choose-us button:hover{
  cursor: pointer;
  background-color: white;
  color: #26017b;
}


.connect-list {
  list-style: none; 
  padding-left: 0; 
}

.connect-list li {
  position: relative; 
  padding-left: 30px; 
  margin-bottom: 2rem;
}

.connect-list li::before {
  content: ""; 
  background-image: url("../assets/icons/arrow.png"); 
  background-size: contain; 
  background-repeat: no-repeat; 
  position: absolute; 
  left: 0; 
  top: 20%; 
  transform: translateY(-50%); 
  width: 20px; 
  height: 20px; 
}

.connect-button{
  border-radius: 100px;
  font-weight: bold;
  margin-top: 2rem;
  background-color: #00a5e0;
  color: white;
  transition: background-color 0.5s;
  border: 2px solid #00a5e0;
}

.connect-button:hover{
  background-color: white;
  border: 2px solid #00a5e0;
  color: #00a5e0;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 700px;
  max-width: 100%;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.quote-popup-container {
  display: flex;
  justify-content: space-between;
  z-index: 99999;
}

.quote-left,
.quote-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-image {
  max-width: 100%;
  height: auto;
}

.quote-btn {
  padding: 2px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50px;
  background-color: white;
  color: #26017b;
  border: 3px solid #26017b;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 40px;
}
