/*.banner-container {
  display: flex;
  justify-content: center;
  background-color: #26017b;
  max-width: 500rem;
  width: 100%;
  max-height: 400px;
  height: 300px;
}



@media (max-width: 768px) {
  .banner-container {
    height: auto;
    max-height: none;
    padding: 20px;
  }

  .banner-content p {
    font-size: 1.2rem;
  }

  .banner-content p:nth-child(2) {
    font-size: 1rem;
    margin-top: -1rem;
  }

  .banner-content .get-startedbtn {
    font-size: 20px;
    padding: 7px;
  }
}

@media (max-width: 480px) {
  .banner-container {
    height: auto;
    padding: 15px;
  }

  .banner-content p {
    font-size: 1rem;
  }

  .banner-content p:nth-child(2) {
    font-size: 0.9rem;
    margin-top: -1rem;
  }

  .banner-content .get-startedbtn {
    font-size: 18px;
    padding: 5px;
  }

  .get-startedbtn {
    margin-bottom: 2rem;
  }
}*/

.get-startedbtn {
  transition: background-color 0.3s, color 0.3s;
  background-color: #00a5e0;
  color: white;
  border: 3px solid #26017b;
  border-radius: 100px;
}

.get-startedbtn:hover {
  cursor: pointer;
  background-color: white;
  color: #26017b;
}