*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
}

.content-container {
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

footer.footer {
  width: 100%;
  position: relative;
  bottom: 0;
}

.button {
  border: none;
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better UX */
}

body {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .content-container {
    width: 100%; /* Make container fully responsive */
    padding: 0;
    height: auto;
  }

  .navbar {
    width: 100%; /* Navbar spans the entire screen */
  }
}

.hero {
  margin-top: 8rem;
}



.login {
  background-color: #aa98d1;
}
